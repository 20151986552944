import { CTAButton } from "@/components/CTAButton";
import { useDeviceQuery } from "@/utils";
import { Box, rem, Transition } from "@mantine/core";
import { useWindowScroll } from "@mantine/hooks";
import { useTranslation } from "next-i18next";
import { useEffect } from "react";

export default function StickyFooter({
  searchRef,
}: {
  searchRef: React.RefObject<HTMLDivElement> | null;
}) {
  const [isMobile] = useDeviceQuery();
  const [scroll, scrollTo] = useWindowScroll();
  const { t } = useTranslation();
  const isFooterBtnMounted = isMobile! && scroll.y >= 755;

  const adjustFreshchatBotPosition = () => {
    const styleElement = document.createElement("style");
    styleElement.id = "custom-style";
    document.head.appendChild(styleElement);
    const bottomValue = isFooterBtnMounted ? "80px" : "10px";
    styleElement.innerHTML = `
      #fc_frame.fc-mobile-view {
        bottom: ${bottomValue} !important;
      }
    `;
  };

  useEffect(() => {
    adjustFreshchatBotPosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFooterBtnMounted]);

  return (
    <>
      <Transition
        mounted={isFooterBtnMounted}
        transition="slide-up"
        duration={400}
        timingFunction="ease"
      >
        {(styles) => (
          <Box
            pos="sticky"
            className={`z-[100] border-solid border-t border-dark-5 border-l-0
                          border-r-0 border-b-0`}
            bottom={0}
            w="100%"
            px={rem(15)}
            py={rem(10)}
            bg="white"
            style={styles}
          >
            <CTAButton
              text={t("common:search.cta")}
              onClick={() =>
                scrollTo({
                  y: searchRef?.current?.offsetTop! - 80,
                })
              }
            />
          </Box>
        )}
      </Transition>
    </>
  );
}
